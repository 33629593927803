import React, { useEffect } from "react";
import BarheaderAdmin from "../BarheaderAdmin";
import NavBarAdmin from "../NavBarAdmin";
import "./css/listFAQ.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Correct usage
import { deleteFaq, getFaq } from "../../../Redux/Slice/FaqSlice";
import OverlayA from "../OverlayA";
import { RiDeleteBin6Line } from "react-icons/ri";

const ListFaq = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Correct usage

  const { Faqs } = useSelector((state) => state.faq);
  const { isAuthadmin } = useSelector((state) => state.faq);

  useEffect(() => {
    if (!isAuthadmin) navigate('/admin/login');
  }, [isAuthadmin, navigate]);
  useEffect(() => {
    dispatch(getFaq());
  }, [dispatch]);

  const handelAccueil = () => {
    navigate("/admin/Accueil");
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this Faq?")) {
      dispatch(deleteFaq(id));
    }
  };

  return (
    <>
      <BarheaderAdmin />
      <NavBarAdmin />
      <OverlayA />
      <div className="ConsultListFaq">
        <div className="ListFaqContainer">
          <button className="AccueilListFaq" onClick={handelAccueil}>
            Accueil
          </button>
          <table
            className="TableListFaq"
            style={{
              borderCollapse: "collapse",
              width: "100%",
              textAlign: "center",
            }}
          >
            <thead>
              <tr>
                <th className="TableHeaderfaq">Question</th>
                <th className="TableHeaderfaq">Réponse</th>
                <th className="TableHeaderfaq">Date</th>
                <th className="TableHeaderfaq">Action</th>
              </tr>
            </thead>
            <tbody>
              {Faqs &&
                Faqs.map((Faq, index) => (
                  <tr key={index}>
                    <td className="DATAfaq">{Faq.question}</td>
                    <td className="DATAfaq">{Faq.rreponse}</td>
                    <td className="DATAfaq">{Faq.dates}</td>
                    <td className="DATAfaq">
                      <RiDeleteBin6Line
                        style={{ fontSize: "20px", color: "black" }}
                        onClick={() => handleDelete(Faq._id)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ListFaq;
