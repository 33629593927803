import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BarheaderAdmin from "../BarheaderAdmin";
import NavBarAdmin from "../NavBarAdmin";
import { sendEmail } from "../../../Redux/Slice/emailSlice";
import "./css/emailnewsletter.css";
import { useNavigate } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DOMPurify from 'dompurify';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import OverlayA from "../OverlayA";
import { GetNewletter } from "../../../Redux/Slice/NewsLetterSlice";

const EmailingNewsletter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { NewsLetter, error,isAuthadmin } = useSelector((state) => state.newsletter);

  const [emailMessage, setEmailMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    if (!isAuthadmin) navigate('/admin/login');
  }, [isAuthadmin, navigate]);
  useEffect(() => {
    dispatch(GetNewletter());
  }, [dispatch]);
  const handelAccueil = () => {
    navigate("/admin/Accueil");

  };

  const handleSendEmail = () => {
    // Remove the additional quotes
    const NewsLetterEmail = NewsLetter.map((newsLetter) => newsLetter.email).join(',');
    
    // Sanitize the email message
    const cleanedHtml = DOMPurify.sanitize(emailMessage);
    
    // Log to verify the correct format
    console.log(NewsLetterEmail);
    
    // Dispatch the sendEmail action
    dispatch(sendEmail({ email: NewsLetterEmail, subject: subject, message: cleanedHtml }));
  
    // Show alert and navigate after 3 seconds
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      navigate('/admin/Newsletter');
    }, 3000);
  };
  
  return (
    <>
      <BarheaderAdmin />
      <NavBarAdmin />
      <OverlayA />

      <div style={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1200 }}>
        {showAlert && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ marginBottom: '20px', width: '100%' }}>
            Email envoyé avec succès !
          </Alert>
        )}
        {error && (
          <Alert severity="error" style={{ marginBottom: '20px', width: '100%' }}>
            {error}
          </Alert>
        )}
      </div>

      <div className="ConsultEmail" style={{ paddingTop: showAlert || error ? '70px' : '20px' }}>
        <div className="ConsultEmailContainer">
          <button className="AccueilNews" onClick={handelAccueil}>Newsletter</button>
          <h3 className="emailingNews">Emailing Newsletter</h3>
          <label>Objet:</label>
          <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
          <label>Message:</label>
          <CKEditor
            editor={ClassicEditor}
            data={emailMessage}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEmailMessage(data);
            }}
            config={{
              toolbar: [
                'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',
                '|', 'undo', 'redo', 'alignment', 'imageUpload', 'insertTable', 'mediaEmbed', 'removeFormat'
              ],
            }}
          />
          <button onClick={handleSendEmail} className="btn-send">Envoyer</button>
        </div>
      </div>
    </>
  );
};

export default EmailingNewsletter;

