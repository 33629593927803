import React, { useEffect } from "react";
import BarheaderAdmin from "../BarheaderAdmin";
import NavBarAdmin from "../NavBarAdmin";
import "./css/temoignage.css";

import {  useNavigate } from "react-router-dom";
import Deconnection from "../Deconnection";
import { useSelector } from "react-redux";
const Temoignage = () => {
  const navigate=useNavigate()
  const { isAuthadmin } = useSelector((state) => state.temoignage);

  const handelListVesible = () => {
    navigate("/admin/témoignages/visible");
  };
  useEffect(() => {
    if (!isAuthadmin) navigate('/admin/login');
  }, [isAuthadmin, navigate]);
  const handelListInvesible = () => {
    navigate("/admin/témoignages/invisible");
  };
  return (
<>

<BarheaderAdmin />
      <NavBarAdmin />
   <Deconnection/>
      <div className="ConsultTemoignage">
        <div className="ConsultTemoignageContainer">
          <button className="AccueilTemoignage" onClick={handelListInvesible}>Liste des témoignages invisibles</button>
          <button className="AccueilTemoignage" onClick={handelListVesible}>Liste des témoignages visibles </button>
         
        </div>
      </div>
      </>
)
}

export default Temoignage