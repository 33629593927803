import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./css/viewcoach.css";
import { getCoachVisivble } from "../../../Redux/Slice/CoachSlice";
import { BASE_IMAGE_URL, getImageUrl } from "../../..";

const ViewCoachVisible = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const { coachVisible ,isAuthadmin} = useSelector((state) => state.coach);
  useEffect(() => {
    if (!isAuthadmin) navigate('/admin/login');
  }, [isAuthadmin, navigate]);
  useEffect(() => {
    dispatch(getCoachVisivble());
  }, [dispatch]);

  useEffect(() => {
    if (coachVisible && id) {
      const CoachView = coachVisible.find((coach) => coach._id === id);
      if (CoachView) {
        setFormData(CoachView);
      }
    }
  }, [coachVisible, id]);
  const handleDownload = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    const link = document.createElement("a");
    link.href = `${BASE_IMAGE_URL}${formData.piece}`; // Set the URL of the file
    link.target = "_blank"; // Open in new tab
    link.click(); // Trigger the download
    link.remove(); // Remove the anchor element from the DOM
  }
  return (
    <div className="ViewCoach">
      <div className="viewCoachContainer">
        <h3 className="TitreView">Voir Enregistrement</h3>
        <hr style={{ color: "#ddd" }} />

        <div className="CoachContainer">

          <div className="border-bottom">
            <label className="LabelViewCoach">Nom et Prénom</label>
            <p className="styletexteCoach">{formData.nom}</p>
          </div>


          <div className="border-bottom">
            <label className="LabelViewCoach">Numéro Téléphone</label>
            <p className="styletexteCoach">{formData.num}</p>
          </div>


          <div className="border-bottom">
            <label className="LabelViewCoach">Domaines D'intervention</label>
            <div className="styletexteCoach ">
              {formData.domain?.map((domain, index) => (
                <p key={index} className="styletexteCoach">
                  * {domain}{" "}
                </p>
              ))}
              {formData.AutreDomaine && (
                <p className="styletexteCoach">* {formData.AutreDomaine}</p>
              )}
            </div>
          </div>

          <div className="border-bottom">
            <label className="LabelViewCoach">Adresse Email</label>
            <p className="styletexteCoach">{formData.email}</p>
          
        </div>
        
        
          <div >
            <label className="LabelViewCoach"> Bréve Bio</label>
            <p className="styletexteCoach border-bottom">{formData.bio}</p>
          </div>


          <label className="LabelViewCoach">Méthode de Coaching</label>
          <div
            className="styletexteCoach border-bottom"
           
          >
            {formData.method?.map((methode, index) => (
              <p key={index} className="styletexteCoach">
                * {methode}{" "}
              </p>
            ))}
          </div>


          <label className="LabelViewCoach">Langue</label>
          <div
            className="styletexteCoach border-bottom"
          
          >
            {formData.langue?.map((Langue, index) => (
              <p key={index} className="styletexteCoach">
                * {Langue}{" "}
              </p>
            ))}
          </div>

          <label className="LabelViewCoach">Type_Client</label>
          <div className="styletexteCoach  border-bottom">
            {formData.type_client?.map((TC, index) => (
              <p key={index} className="styletexteCoach">
                * {TC}{" "}
              </p>
            ))}
          </div>

          <div
         className="border-bottom"
          >
            <label className="LabelViewCoach">Photo</label>
            <img
              src={getImageUrl(formData.image)}
              style={{ display: "block", margin: "auto", width: "100px" }}
               alt="Coach"
            />
          </div>

          <div
          className="border-bottom"
          >
            <label className="LabelViewCoach">Site</label>
            <p className="styletexteCoach">{formData.site}</p>
          </div>

          <div
          className="border-bottom"
          >
            <label className="LabelViewCoach">Facebook</label>
            <p className="styletexteCoach">{formData.fb}</p>
          </div>

          <div
         className="border-bottom"
          >
            <label className="LabelViewCoach">Linkedin</label>
            <p className="styletexteCoach">{formData.In}</p>
          </div>

          <div
          className="border-bottom"
          >
            <label className="LabelViewCoach">You Tube</label>
            <p className="styletexteCoach">{formData.yt}</p>
          </div>
          <div className="border-bottom">
  <label className="LabelViewCoach">Piéce Jointe</label>
  {formData.piece && (
    <p className="styletexteCoach" onClick={handleDownload}>
      {formData.piece.split('/').pop()} {/* This will display only the file name */}
    </p>
  )}
</div>

          <div
        className="border-bottom"
          >
            <label className="LabelViewCoach">Gouvernerats</label>
            <p className="styletexteCoach">{formData.gouv}</p>
          </div>
          <div
         className="border-bottom"
          >
            <label className="LabelViewCoach">Tarif</label>
            <p className="styletexteCoach">{formData.tarif ? "oui" : "non"}</p>
          </div>
        </div>
        <button className="button-retour" onClick={() => navigate("/admin/Coachs/visible")}>
          Retour
        </button>
      </div>
    </div>
  );
};

export default ViewCoachVisible;
