import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import "./css/editvideo.css";
import loadingGif from "./../../../images/loading.gif";
import { getImageUrl } from "../../../index.js";
import { Getvideo, putvideo } from "../../../Redux/Slice/videoSlice.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Editvideo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { video, loading ,isAuthadmin} = useSelector((state) => state.video);
  const [image, setImage] = useState(null);
  const [titre, setTitre] = useState("");

  const [formData, setFormData] = useState({
    titre: "",
    lien: "",
  });
  useEffect(() => {
    if (!isAuthadmin) navigate('/admin/login');
  }, [isAuthadmin, navigate]);
  useEffect(() => {
    dispatch(Getvideo());
  }, [dispatch]);

  useEffect(() => {
    if (video && id) {
      const videoEdit = video.find((video) => video._id === id);
      if (videoEdit) {
        setFormData(videoEdit);
        setTitre(videoEdit.titre); // Set the initial title state
      }
    }
  }, [video, id]);

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setTitre(data);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!titre || !formData.lien) {
      alert("Please fill in all required fields.");
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("titre", titre);
    formDataToSend.append("lien", formData.lien);

    if (image) {
      formDataToSend.append("images", image);
    }

    dispatch(putvideo({ id, data: formDataToSend }));
    navigate("/admin/videoCoching");
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img src={loadingGif} alt="Chargement..." />
      </div>
    );
  }

  return (
    <div className="edit-Evenement">
      <div className="FormContainerEvenement">
        <form onSubmit={handleSubmit}>
          <div className="ItemVedioEdit">
            <label className="LabelEditVedio">Titre</label>
            <CKEditor
              editor={ClassicEditor}
              data={formData.titre}
              onChange={handleEditorChange}
            />
          </div>
          <div className="ItemVedioEdit">
            <label className="LabelEditVedio">Lien</label>
            <textarea
              className="inputVideoEdit"
              type="text"
              name="lien"
              value={formData.lien}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group-Video">
            <label className="LabelEditVedio">Image</label>
            <input type="file" name="images" onChange={handleFileChange} />
            {formData.images && !image && (
              <img
                src={getImageUrl(formData.images)}
                alt="Icone"
                className="imageEdit-video"
              />
            )}
          </div>
          <div className="Bouton-Edit-Article">
            <button type="submit" className="btn-EditVideo">
              Modifier
            </button>
            <button
              type="button"
              onClick={() => navigate("/admin/Accueil")}
              className="btn-NoEditVideo"
            >
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Editvideo;
