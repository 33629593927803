import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BarheaderAdmin from "../BarheaderAdmin";
import NavBarAdmin from "../NavBarAdmin";
import { sendEmail } from "../../../Redux/Slice/emailSlice";
import "./css/emailcoach.css";
import { useNavigate } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import OverlayA from "../OverlayA";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

const EmailingCoach = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { coachVisible, selectedCoaches,isAuthadmin } = useSelector((state) => state.coach);

  const [emailMessage, setEmailMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const handelAccueil = () => {
    navigate("/admin/Accueil");
  };
  useEffect(() => {
    if (!isAuthadmin) navigate('/admin/login');
  }, [isAuthadmin, navigate]);
  const handleSendEmail = () => {
    const selectedCoachEmails = coachVisible
      .filter((coach) => selectedCoaches.includes(coach._id))
      .map((coach) => coach.email);

    dispatch(sendEmail({ email: selectedCoachEmails, subject: subject, message: emailMessage }));

    // Show the alert
    setShowAlert(true);

    // Hide the alert and navigate after a delay
    setTimeout(() => {
      setShowAlert(false);
      navigate("/admin/Base-Coach");
    }, 3000); // Delay of 3 seconds

    console.log("Email Sent");
  };

  return (
    <>
      <BarheaderAdmin />
      <NavBarAdmin />
      <OverlayA />

      {/* Full-width alert at the top */}
      <div style={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1200 }}>
        {showAlert && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ marginBottom: '20px', width: '100%' }}>
            Email envoyé avec succès !
          </Alert>
        )}
      </div>

      <div className="ConsultEmail" style={{ paddingTop: showAlert ? '70px' : '20px' }}>
        <div className="ConsultEmailContainer">
          <button className="AccueilData" onClick={handelAccueil}>Base des coachs</button>
          <h3 className="EmailingCoach">Emailing Coachs</h3>
          <label>Objet:</label>
          <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
          <label>Message:</label>
          <CKEditor
            editor={ClassicEditor}
            data={emailMessage}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEmailMessage(data);
            }}
            config={{
              toolbar: [
                'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',
                'insertTable', 'mediaEmbed', 'undo', 'redo'
              ],
              height: 500,
            }}
          />
          <button onClick={handleSendEmail} className="SendEmail">Envoyer</button>
        </div>
      </div>
    </>
  );
};

export default EmailingCoach;
