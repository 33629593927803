import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BarheaderAdmin from '../BarheaderAdmin.js';
import NavBarAdmin from '../NavBarAdmin.js';
import './css/AddPub.css';
import { AddPublic } from '../../../Redux/Slice/PubAtelierSlice.js';
import OverlayA from '../OverlayA.js';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AddPublication = () => {
    const [titre, setTitre] = useState('');
    const [texte, setTexte] = useState('');
    const [photo, setPhoto] = useState(null);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { isAuthadmin } = useSelector((state) => state.atelier);

    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthadmin) navigate('/admin/login');
      }, [isAuthadmin, navigate]);
    const handleTitreChange = (e) => {
        setTitre(e.target.value);
    };

    const handlePhotoChange = (e) => {
        setPhoto(e.target.files[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('titre', titre);
        formDataToSend.append('texte', texte);
        formDataToSend.append('img', photo);

        dispatch(AddPublic({ id, data: formDataToSend }));
        navigate('/admin/atelier-A');
    };

    const handelAccueil = () => {
        navigate("/admin/Accueil");
    };

    return (
        <>
            <BarheaderAdmin />
            <NavBarAdmin />
            <OverlayA />
            <div className="addPub">
                <div className="addPubContainer">
                    <button className="buttonAccueil" onClick={handelAccueil}>
                        Accueil
                    </button>
                    <form onSubmit={handleSubmit}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label className='LabelAddPub'>Titre:</label>
                            <input type="text" className="inputAddPubl" value={titre} onChange={handleTitreChange} required />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label className='LabelAddPub'>Texte:</label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={texte}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setTexte(data);
                                }}
                                config={{
                                    toolbar: [
                                        'heading', '|',
                                        'bold', 'italic', '|',
                                        'bulletedList', 'numberedList', '|',
                                        'insertImage', '|',
                                        'blockQuote', 'link', '|',
                                        'undo', 'redo'
                                    ],
                                }}
                                className="editorPub"
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label className='LabelAtelier'>Photo:</label>
                            <input type="file" onChange={handlePhotoChange} required />
                        </div>
                        <div className='button-Container'>
                            <button type="submit" className='btnAddPub'>Ajouter</button>
                            <button type="button" className='btnAnnulerAdd' onClick={() => navigate("/admin/atelier-A")}>Annuler</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AddPublication;
