import React, { useEffect } from "react";
import BarheaderAdmin from "../BarheaderAdmin";
import NavBarAdmin from "../NavBarAdmin";
import "./css/evenementA.css";

import { useNavigate } from "react-router-dom";
import Deconnection from "../Deconnection";
import { useSelector } from "react-redux";
const EvenementA = () => {
  const navigator=useNavigate()
  const {isAuthadmin} = useSelector((state) => state.evenement);

  useEffect(() => {
    if (!isAuthadmin) navigator('/admin/login');
  }, [isAuthadmin, navigator]);
  const handelAddEvnt = () => {
    navigator("/admin/Evenements/AjouterEvenement")};
const handelListEvnt = () => {
  navigator("/admin/Evenements/liste")};
  return (
<>

<BarheaderAdmin />
      <NavBarAdmin />
   <Deconnection/>
      <div className="ConsultEvenement">
        <div className="ConsultEvenementContainer">
          <button className="AccuEvenement" onClick={()=>handelAddEvnt()}>Ajouter un événement</button>
          <button className="AccuEvenement" onClick={()=>handelListEvnt()}>Liste des événements </button>
         
        </div>
      </div>
      </>  )
}

export default EvenementA