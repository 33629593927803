import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./css/editEvenement.css";
import { GetEvenement, putEvenement } from "../../../Redux/Slice/EvenementSlice";
import loadingGif from "./../../../images/loading.gif";
import { getImageUrl } from "../../../index.js";

const EditEvenement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { Evenement,isAuthadmin } = useSelector((state) => state.evenement);
  const [image, setImage] = useState(null);
  const [Texte, setTexte] = useState("");

  const [formData, setFormData] = useState({
    titre: "",
    texte: "",
    lien: "",
    lieu: "",
    dates: "",
    photo: "",
  });
  const [editorLoaded, setEditorLoaded] = useState(false);
  useEffect(() => {
    if (!isAuthadmin) navigate('/admin/login');
  }, [isAuthadmin, navigate]);
  useEffect(() => {
    setEditorLoaded(true);
    dispatch(GetEvenement());
  }, [dispatch]);

  useEffect(() => {
    if (Evenement && id) {
      const EvenementEdit = Evenement.find((EVT) => EVT._id === id);
      if (EvenementEdit) {
        setFormData(EvenementEdit);
        setTexte(EvenementEdit.texte); // Set CKEditor content
      }
    }
  }, [Evenement, id]);

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("titre", formData.titre);
    formDataToSend.append("texte", Texte);
    formDataToSend.append("lien", formData.lien);
    formDataToSend.append("lieu", formData.lieu);
    formDataToSend.append("dates", formData.dates);

    if (image) {
      formDataToSend.append("photo", image);
    }

    dispatch(putEvenement({ id, data: formDataToSend }));
    navigate("/admin/Evenements/liste");
  };

  if (!editorLoaded) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img src={loadingGif} alt="Chargement..." />
      </div>
    );
  }

  return (
    <div className="edit-Evenement">
      <div className="FormContainerEvenement">
        <form onSubmit={handleSubmit}>
          <div className="ItemEvntEdit">
            <label className="labelEditEvnt">Titre</label>
            <input
              className="InputEditEvnt"
              type="text"
              name="titre"
              value={formData.titre}
              onChange={handleInputChange}
            />
          </div>
          <div className="ItemEvntEdit">
            <label className="labelEditEvnt">Texte</label>
            <CKEditor
              editor={ClassicEditor}
              data={Texte}
              onChange={(event, editor) => {
                const data = editor.getData();
                setTexte(data);
              }}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "blockQuote",
                  "insertTable",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
            />
          </div>
          <div className="ItemEvntEdit">
            <label className="labelEditEvnt">Lien</label>
            <input
              className="InputEditEvnt"
              type="text"
              name="lien"
              value={formData.lien}
              onChange={handleInputChange}
            />
          </div>
          <div className="ItemEvntEdit">
            <label className="labelEditEvnt">Lieu</label>
            <input
              className="InputEditEvnt"
              type="text"
              name="lieu"
              value={formData.lieu}
              onChange={handleInputChange}
            />
          </div>
          <div className="ItemEvntEdit">
            <label className="labelEditEvnt">Date</label>
            <input
              className="InputEditEvnt"
              type="text"
              name="dates"
              value={formData.dates}
              onChange={handleInputChange}
            />
          </div>
          <div className="ItemEvntEdit">
            <label className="labelEditEvnt">Image</label>
            <input
              type="file"
              name="photo"
              onChange={handleFileChange}
              style={{ margin: "20px 0", display: "block" }}
            />
            {formData.photo && !image && (
              <img
                src={getImageUrl(formData.photo)}
                alt="Icone"
                className="imageEdit-Article"
              />
            )}
          </div>
          <div className="buttonsContainer">
            <button type="submit" className="btnEditEvnt ">
              Modifier
            </button>
            <button
              type="button"
              className="btnAnnuleEdit"
              onClick={() => navigate("/admin/Accueil")}
            >
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEvenement;
