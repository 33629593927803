import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BarheaderAdmin from '../BarheaderAdmin';
import NavBarAdmin from '../NavBarAdmin';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { sendEmail } from '../../../Redux/Slice/emailSlice';
import image from "../../../images/big_image_2.jpg";
import './css/ContactEmailA.css';
import DOMPurify from 'dompurify';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

const ContactEmailA = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Contacts,isAuthadmin } = useSelector((state) => state.contact);
  const { id } = useParams();
  const [contact, setContact] = useState(null);
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState({ type: '', message: '' });
  const form = useRef();
  useEffect(() => {
    if (!isAuthadmin) navigate('/admin/login');
  }, [isAuthadmin, navigate]);
  useEffect(() => {
    if (Contacts.length > 0) {
      const foundContact = Contacts.find((contact) => contact._id === id);
      setContact(foundContact);
    }
  }, [Contacts, id]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setMessage(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!contact) {
      setAlert({ type: 'error', message: 'Contact not found!' });
      return;
    }

    const templateParams = {
      email: contact.email,
      subject: form.current.subject.value,
      message: DOMPurify.sanitize(message),
    };

    dispatch(sendEmail(templateParams))
      .then(() => {
        setAlert({ type: 'success', message: 'Email sent successfully!' });
        setTimeout(() => {
          navigate("/admin/Contact");
        }, 3000);
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
        setAlert({ type: 'error', message: 'Failed to send email: ' + error.message });
        setTimeout(() => {
          navigate("/admin/Contact");
        }, 3000);
      });
  };

  if (!contact) {
    return <div>Loading or Contact not found...</div>;
  }

  return (
    <>
      <BarheaderAdmin />
      <NavBarAdmin />
      <div className="ImagePlatformeContact" style={{ backgroundImage: `url(${image})` }}>
        <div>
          <h3 className='TitreContact'>Formulaire d'envoi d'email</h3>
        </div>
      </div>
      <div className='EmailContact'>
        {alert.message && (
          <div style={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
            <Alert
              icon={alert.type === 'success' ? <CheckIcon fontSize="inherit" /> : <ErrorIcon fontSize="inherit" />}
              severity={alert.type}
              style={{ marginBottom: '20px' }}
            >
              {alert.message}
            </Alert>
          </div>
        )}
        <div className='EmailContainer'>
          <form ref={form} onSubmit={handleSubmit}>
            <input type="hidden" name="to_email" value={contact.email} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label className='LabelContact'>Objet</label>
              <input type="text" name="subject" className='inputObjet' />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label className='LabelContact'>Message</label>
              <CKEditor
                editor={ClassicEditor}
                data={message}
                onChange={handleEditorChange}
                config={{
                  toolbar: [
                    'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'undo', 'redo'
                  ],
                }}
              />
            </div>
            <input type="submit" value="Envoyer" className='sendemail' />
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactEmailA;
